.board-class {
  background: #2c9b00;
}
.sel-table-ad {
  cursor: pointer;
}
.table-header {
  background-color: rgb(243, 6, 6);
}
.drawerHeight {
  height: 50vh;
}