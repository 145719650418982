.dim-ing {
  background-color: #fff;
  border-radius: 8px;
}
.is-save {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  left: -5px;
  top: -8px;
  background-color:green;
}
.is-savex {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  left: -5px;
  top: -8px;
  background-color:red;
}
.size-icon-check {
  position: absolute;
  color: #fff;
}
.size-icon {
  position: absolute;
  color: #fff;
  animation: loadding 2s infinite linear;
}
@keyframes loadding {
  from{transform: rotate(360deg)}
  to{transform: rotate(0deg)}
}

.click-on-view-mantdata {
  cursor: pointer;
}

.table-cell-view:hover {
  background-color: rgba(80, 79, 79, 0.131);
}

.downfile-ad-1 {
  position: absolute;
  color: #fff;
  left: 15px;
  top: -55px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 202, 50, 0.761);
  
}
.downfile-ad {
  position: fixed;
  color: #fff;
  left: 15px;
  bottom: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 138, 34, 0.857);
  /* box-shadow: 0px 0px 5px 20px rgba(0, 0, 0, 0.782); */
  animation: pulseDonwloading 2s infinite linear;
  z-index: 100;
}
@keyframes pulseDonwloading {
  0%{
    box-shadow: 0px 0px 5px 15px rgba(4, 9, 0, 0.5);
  }
  30%{
    box-shadow: 0px 0px 5px 8px rgba(0, 0, 0, 0.3);
  }
  50%{
    box-shadow: 0px 0px 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  75%{
    box-shadow: 0px 0px 5px 8px rgba(4, 5, 0, 0.3);
  }
  100%{
    box-shadow: 0px 0px 5px 15px rgba(0, 0, 0, 0.5);
  }
}