.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-app-drawler {
  text-decoration: none;
}

.menu-app-drawler:hover {
  background: rgb(205,205,205);
background: -moz-linear-gradient(90deg, rgba(205,205,205,0.7595413165266106) 35%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(205,205,205,0.7595413165266106) 35%, rgba(255,255,255,1) 100%);
background: linear-gradient(90deg, rgba(205,205,205,0.7595413165266106) 35%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cdcdcd",endColorstr="#ffffff",GradientType=1);
}

.cursor-menu {
  cursor: pointer;
}

.bg-blanco {
  background-color: #fff;
}

.user-info {
  padding-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  color: #fff;
  background-color: rgba(252, 8, 8, 0.487);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.59);
}